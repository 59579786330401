import * as React from 'react'
import PropTypes from 'prop-types'

import Image from './partials/image'
import Embed from './partials/embed'

const Dynamic = ({ component }) => {
	// Run for All Components in Dynamic Zone
	return component.map(data => {
		switch (data.__typename) {
			// Single Image Component
			case 'STRAPI_ComponentSectionSingleImage':
				return <Image key={`single_image__${data.id}`} asset={data.image} />

			// Vimeo Embed Component
			case 'STRAPI_ComponentSectionEmbed':
				return (
					<div key={`embed__${data.id}`} className="bg-placeholder">
						<Embed embed={data} />
					</div>
				)

			default:
				return null
		}
	})
}

Dynamic.propTypes = {
	component: PropTypes.arrayOf(
		PropTypes.shape({
			__typename: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			image: PropTypes.shape({}),
		}).isRequired
	).isRequired,
}

export default Dynamic
