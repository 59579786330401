import * as React from 'react'
import PropTypes from 'prop-types'

import Image from '../partials/image'
import Embed from '../partials/embed'

import PortfolioCarousel from './carousel'
import PortfolioText from './text'

const PortfolioSections = ({ component }) => {
	// Run for All Components in Project Dynamic Zones
	return component.map(data => {
		switch (data.__typename) {
			// Fixed Image Component
			case 'STRAPI_ComponentSectionImages':
				return data.images.map(image => (
					<Fixed key={`fixed_images__${image.id}`}>
						<Image asset={image} />
					</Fixed>
				))

			// Flexible Image Component
			case 'STRAPI_ComponentSectionFlexibleImages':
				return data.flexible.map(image => (
					<Flexible key={`flexible_images__${image.id}`}>
						<Image asset={image} />
					</Flexible>
				))

			// Vimeo Embed Component
			case 'STRAPI_ComponentSectionEmbed':
				return (
					<Fixed key={`fixed_embed__${data.id}`}>
						<Embed embed={data} />
					</Fixed>
				)

			// Side Image-Image Component
			case 'STRAPI_ComponentSectionImageImage':
				return (
					<Side key={`side_image_image__${data.id}`}>
						<Image asset={data.left_image} />
						<Image asset={data.right_image} />
					</Side>
				)

			// Side Image-Embed Component
			case 'STRAPI_ComponentSectionImageEmbed':
				return (
					<Side key={`side_image_embed__${data.id}`}>
						<Image asset={data.left_image} />
						<Embed embed={data.right_embed} />
					</Side>
				)

			// Side Embed-Image Component
			case 'STRAPI_ComponentSectionEmbedImage':
				return (
					<Side key={`side_embed_image__${data.id}`}>
						<Embed embed={data.left_embed} />
						<Image asset={data.right_image} />
					</Side>
				)

			// Side Embed-Embed Component
			case 'STRAPI_ComponentSectionEmbedEmbed':
				return (
					<Side key={`side_embed_embed__${data.id}`}>
						<Embed embed={data.left_embed} />
						<Embed embed={data.right_embed} />
					</Side>
				)

			// Fixed Carousel Component
			case 'STRAPI_ComponentSectionFixedCarousel':
				return (
					<Fixed key={`fixed_carousel__${data.id}`}>
						<PortfolioCarousel
							count={data.number}
							slides={data.slides}
							speed={data.speed}
							mode="fixed"
						/>
					</Fixed>
				)

			// Flexible Carousel Component
			case 'STRAPI_ComponentSectionFlexibleCarousel':
				return (
					<Fixed key={`flexible_carousel__${data.id}`}>
						<PortfolioCarousel
							count={data.visible}
							slides={data.slides}
							speed={data.speed}
							mode="flexible"
						/>
					</Fixed>
				)

			// Side Image-Content Component
			case 'STRAPI_ComponentSectionImageContent':
				return (
					<Content
						key={`side_image_content__${data.id}`}
						position={data.image_position}
					>
						<Image asset={data.image} />
						<PortfolioText content={data.image_content} />
					</Content>
				)

			// Side Embed-Content Component
			case 'STRAPI_ComponentSectionEmbedContent':
				return (
					<Content
						key={`side_embed_content__${data.id}`}
						position={data.embed_position}
					>
						<Embed embed={data.embed} />
						<PortfolioText content={data.embed_content} />
					</Content>
				)

			// Spacer Component
			case 'STRAPI_ComponentSectionSpacer':
				return (
					<Fixed key={`spacer__${data.id}`}>
						<span style={{ marginTop: data.space + 'px' }}></span>
					</Fixed>
				)

			// Text Component
			case 'STRAPI_ComponentSectionText':
				return (
					<Fixed key={`text__${data.id}`}>
						<PortfolioText content={data.content} />
					</Fixed>
				)

			default:
				return null
		}
	})
}

PortfolioSections.propTypes = {
	component: PropTypes.arrayOf(
		PropTypes.shape({
			__typename: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			images: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string.isRequired,
				})
			),
			flexible: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string.isRequired,
				})
			),
			left_image: PropTypes.shape({}),
			right_image: PropTypes.shape({}),
			left_embed: PropTypes.shape({}),
			right_embed: PropTypes.shape({}),
			number: PropTypes.string,
			visible: PropTypes.string,
			speed: PropTypes.number,
			slides: PropTypes.arrayOf(PropTypes.shape({})),
			content: PropTypes.string,
			embed: PropTypes.shape({}),
			embed_content: PropTypes.string,
			embed_position: PropTypes.string,
			image: PropTypes.shape({}),
			image_content: PropTypes.string,
			image_position: PropTypes.string,
		}).isRequired
	).isRequired,
}

// Fixed Container
const Fixed = ({ children }) => (
	<div className="container max-w-section">
		<section className="py-20 lg:py-40">{children}</section>
	</div>
)

Fixed.propTypes = {
	children: PropTypes.node.isRequired,
}

// Flexible Container
const Flexible = ({ children }) => (
	<div className="w-full">
		<section className="py-20 lg:py-40">{children}</section>
	</div>
)

Flexible.propTypes = {
	children: PropTypes.node.isRequired,
}

// Side by Side Container
const Side = ({ children }) => (
	<div className="container max-w-section grid grid-cols-1 lg:grid-cols-2 lg:gap-30">
		<section className="py-20 lg:py-40">{children[0]}</section>
		<section className="py-20 lg:py-40">{children[1]}</section>
	</div>
)

Side.propTypes = {
	children: PropTypes.node.isRequired,
}

// Side by Side Content Container
const Content = ({ children, position }) => {
	let left, right

	if (position === 'Left') {
		left = 'col-start-1'
		right = 'col-start-2'
	} else {
		left = 'col-start-2'
		right = 'col-start-1'
	}

	return (
		<div className="container lg:w-10/12 items-center text-center sm:text-left grid grid-cols-1 sm:grid-cols-2 lg:gap-30">
			<section
				className={`pt-30 pb-10 sm:pt-40 xxl:py-40 sm:row-start-1 sm:${left}`}
			>
				{children[0]}
			</section>
			<section
				className={`pt-10 pb-30 sm:pb-40 xxl:py-40 sm:row-start-1 sm:${right}`}
			>
				{children[1]}
			</section>
		</div>
	)
}

Content.propTypes = {
	children: PropTypes.node.isRequired,
	position: PropTypes.string.isRequired,
}

export default PortfolioSections
