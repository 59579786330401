import * as React from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'

import Image from '../partials/image'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'

// Fixed & Flexible Project Carousel Component with Adjustable Speed & Visible Slides

const PortfolioCarousel = ({ count, slides, speed, mode }) => {
	let visible

	switch (count) {
		case 'One':
			visible = 1
			break

		case 'Two':
			visible = 2
			break

		case 'Three':
			visible = 3
			break

		case 'Four':
			visible = 4
			break

		case 'Five':
			visible = 5
			break

		default:
			return null
	}

	return (
		<div>
			<Swiper
				modules={[Autoplay]}
				autoplay={{ delay: speed, disableOnInteraction: false }} // Prevent Autoplay Pause
				style={{ overflow: mode === 'fixed' ? 'hidden' : 'visible' }} // Show Duplicated Slides in Flexible Mode
				loop={true}
				loopAdditionalSlides={1}
				slidesPerView={visible}
			>
				{slides.map(slide => {
					return (
						<SwiperSlide key={`carousel_slide__${slide.id}`}>
							<Image asset={slide} />
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}

PortfolioCarousel.propTypes = {
	count: PropTypes.string.isRequired,
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	speed: PropTypes.number.isRequired,
	mode: PropTypes.string.isRequired,
}

export default PortfolioCarousel
