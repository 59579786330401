import * as React from 'react'
import PropTypes from 'prop-types'

// All videos should only come from Vimeo
const Embed = ({ embed }) => {
	return (
		// Padding allows you to control the visible area of the video
		<div className="relative z-10" style={{ paddingTop: embed.padding + '%' }}>
			<iframe
				className="absolute top-0 left-0 w-full h-full"
				src={`https://player.vimeo.com/video/${embed.video_id}?autoplay=1&loop=1&title=0&background=1&byline=0&portrait=0&transparent=1`}
				title={embed.title}
				frameBorder="0"
				allow="autoplay; picture-in-picture"
			/>
		</div>
	)
}

Embed.propTypes = {
	embed: PropTypes.shape({
		video_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		padding: PropTypes.number.isRequired,
	}).isRequired,
}

export default Embed
