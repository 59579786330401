import * as React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

const PortfolioText = ({ content }) => (
	<div className="flex flex-col justify-center sm:w-10/12 lg:w-8/12 mx-auto">
		{parse(content)}
	</div>
)

PortfolioText.propTypes = {
	content: PropTypes.string.isRequired,
}

export default PortfolioText
